body.c1 {
  --sitecolor: #05612e;
  --section-background: transparent;
  --section-color: #000;
  --h1-color: #f5d10f;
  --h2-color: #05612e;
  --h3-color: #05a610;
  --mood-index: #05a610;
  --mood-index-active: #05612e;
  --slash-color: #05a610;
  --link-color: #000;
  --link-color-hover: #05a610;
  --footer-border-color: #f5d10f;
  --weird-transparent-background: rgba(1, 24, 11, 0.8);
}
body.c1 h1 {
  --link-color: #f5d10f;
  --link-color-hover: #05a610;
}
body.c1 h2 {
  --link-color: #05612e;
  --link-color-hover: #000;
}
body.c1 h3 {
  --link-color: #05a610;
  --link-color-hover: #000;
}
body.c1 .section--green,
body.c1 .ns-unitFilter__item,
body.c1 .ns-unitFilter__toggle {
  --section-background: #05612e;
  --section-color: #fff;
  --h2-color: #fff;
  --link-color: #fff;
  --link-color-hover: #f5d10f;
  --slash-color: #f5d10f;
}
body.c1 .section--green-light {
  --section-background: #05a610;
  --slash-color: #f5d10f;
  --h3-color: #fff;
}
body.c1 .section--five {
  --h3-color: #f5d10f;
}
body.c1 .section--footer {
  --section-background: #05612e;
  --section-color: #fff;
  --link-color: #fff;
  --link-color-hover: #f5d10f;
}
body.c1 .section--navigation .navbar,
body.c1 .mobile-navigation {
  --link-color: #fff;
  --slash-color: #f5d10f;
}
body.c1 .section--navigation .navbar .sub2,
body.c1 .mobile-navigation .sub2 {
  --link-color: #000;
}
body.c2 {
  --sitecolor: #c21f1e;
  --section-background: transparent;
  --section-color: #000;
  --h1-color: #f5d10f;
  --h2-color: #4b4b4d;
  --h3-color: #c21f1e;
  --mood-index: #4b4b4d;
  --mood-index-active: #c21f1e;
  --slash-color: #c21f1e;
  --link-color: #000;
  --link-color-hover: #c21f1e;
  --footer-border-color: #c21f1e;
  --weird-transparent-background: #c21f1e;
}
body.c2 h1 {
  --link-color: #f5d10f;
  --link-color-hover: #000;
}
body.c2 h2 {
  --link-color: #4b4b4d;
  --link-color-hover: #000;
}
body.c2 h3 {
  --link-color: #c21f1e;
  --link-color-hover: #000;
}
body.c2 .section--green {
  --section-background: #4b4b4d;
  --section-color: #fff;
  --h3-color: #f5d10f;
  --link-color: #fff;
  --link-color-hover: #f5d10f;
  --slash-color: #f5d10f;
}
body.c2 .section--green-light {
  --section-background: #4b4b4d;
  --section-color: #fff;
  --h2-color: #fff;
  --h3-color: #f5d10f;
  --slash-color: #f5d10f;
}
body.c2 .section--five {
  --h3-color: #f5d10f;
}
body.c2 .section--footer {
  --section-background: #4b4b4d;
  --section-color: #fff;
  --link-color: #fff;
  --link-color-hover: #f5d10f;
}
body.c2 .section--navigation .navbar,
body.c2 .mobile-navigation {
  --link-color: #fff;
  --link-color-hover: #f5d10f;
  --slash-color: #f5d10f;
}
body.c2 .section--navigation .navbar .sub2,
body.c2 .mobile-navigation .sub2 {
  --link-color: #000;
  --link-color-hover: #c21f1e;
}
:root {
  --deskPadding: 73px;
}
@media (max-width: 1023px) {
  :root {
    --deskPadding: 25px;
  }
}
@media (max-width: 767px) {
  :root {
    --deskPadding: 25px;
  }
}
:root {
  --spacePart: 12px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 12px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 12px;
  }
}
:root {
  --spaceUnit: 22px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 22px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 22px;
  }
}
:root {
  --spaceTotal: 34px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 34px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 34px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #05612e;
  line-height: 1.25;
  cursor: pointer;
  color: #f5d10f;
  padding: 10px 30px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}
.button:hover,
.button:focus {
  color: #f5d10f;
  background-color: #044922;
}
.button:active {
  background-color: #01180b;
}
.wrap-wrap {
  float: left;
  width: 100%;
  height: 52.96875vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.mood-wrap {
  position: absolute;
  right: 0;
  top: 0;
}
.head-wrap {
  position: relative;
}
.head-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--sitecolor);
  mix-blend-mode: multiply;
}
#head {
  position: relative;
  width: 260px;
  padding: var(--deskPadding);
}
.cb-layout2 #head,
.cb-layout3 #head {
  width: 330px;
  padding: var(--deskPadding);
}
.news-wrap {
  bottom: -60px;
}
.news-wrap .service_news {
  width: 157px;
  height: 157px;
}
.section--footer .footcontent {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.section--footer .footcontent .footpart {
  flex-basis: 50%;
}
.section--services .service-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
}
.section--services .service-wrap .meta,
.section--services .service-wrap .item {
  margin: 0 8px;
  text-transform: uppercase;
}
.section--one .area {
  width: 100%;
  width: 85.53791887%;
  margin-left: -1.32275132%;
  margin-top: 18px;
  margin-bottom: 40px;
}
.section--one .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 1.54639175%;
  margin-left: 1.54639175%;
  width: 96.90721649%;
}
.section--one .area .tiny {
  width: 46.90721649%;
}
.section--one .area > .slim {
  width: 50%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 3.09278351%;
  margin-left: 3.09278351%;
  width: 93.81443299%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 3.09278351%;
  margin-left: 3.09278351%;
  width: 93.81443299%;
}
.section--one .area > .slim .tiny {
  width: 43.81443299%;
}
.section--five .area {
  width: 100%;
  width: 102.64550265%;
  margin-left: -1.32275132%;
  margin-top: 50px;
  margin-bottom: 24px;
}
.section--five .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--five .area .cb-album .body,
.section--five .area .head,
.section--five .area .foot,
.section--five .area .part {
  margin-right: 1.28865979%;
  margin-left: 1.28865979%;
  width: 97.42268041%;
}
.section--five .area .tiny {
  width: 30.75601375%;
}
.section--five .area > .slim {
  width: 50%;
}
.section--five .area > .slim .head,
.section--five .area > .slim .foot,
.section--five .area > .slim .part {
  margin-right: 2.57731959%;
  margin-left: 2.57731959%;
  width: 94.84536082%;
}
.section--five .area > .slim.cb-album .body {
  margin-right: 2.57731959%;
  margin-left: 2.57731959%;
  width: 94.84536082%;
}
.section--five .area > .slim .tiny {
  width: 44.84536082%;
}
.cb-layout1 .section--two .area {
  margin-top: 50px;
  margin-bottom: 50px;
}
.cb-layout1 .section--two .area .unit {
  width: 102.64550265%;
  margin-left: -1.32275132%;
  min-height: 30.625vw;
  padding-left: 51.32275132%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.cb-layout1 .section--two .area .unit .body {
  padding-top: 25px;
}
.cb-layout1 .section--two .area .unit .part.pict:first-child {
  position: absolute;
  left: 0;
  top: 0;
  width: 47.42268041%;
  height: calc(100% - 24px);
  margin-left: 1.28865979%;
  margin-right: 1.28865979%;
}
.cb-layout1 .section--two .area .unit .part.pict:first-child * {
  height: 100%;
}
.cb-layout1 .section--two .area .unit .part.pict:first-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-layout1 .section--two .area .unit .part {
  width: 94.19795222%;
  margin-left: 2.55972696%;
  margin-right: 2.55972696%;
}
.cb-layout1 .section--two .area .unit .part.tiny {
  width: 44.53924915%;
}
@media (min-width: 1586px) {
  .cb-layout1 .section--two .area .unit {
    min-height: 432px;
  }
}
.cb-layout1 .section--three .area .unit {
  width: 102.64550265%;
  margin-left: -1.32275132%;
  min-height: 40.9375vw;
  padding-right: 51.32275132%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.cb-layout1 .section--three .area .unit .part.pict:first-child {
  position: absolute;
  right: 0;
  top: 0;
  width: 47.42268041%;
  height: calc(100% - 24px);
  margin-left: 1.28865979%;
  margin-right: 1.28865979%;
}
.cb-layout1 .section--three .area .unit .part.pict:first-child * {
  height: 100%;
}
.cb-layout1 .section--three .area .unit .part.pict:first-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-layout1 .section--three .area .unit.slim {
  padding-right: 0;
  padding-left: 51.32275132%;
}
.cb-layout1 .section--three .area .unit.slim .part.pict:first-child {
  right: auto;
  left: 0;
}
.cb-layout1 .section--three .area .unit .part {
  width: 94.19795222%;
  margin-left: 2.55972696%;
  margin-right: 2.55972696%;
}
.cb-layout1 .section--three .area .unit .part.tiny {
  width: 44.53924915%;
}
@media (min-width: 1586px) {
  .cb-layout1 .section--three .area .unit {
    min-height: 649px;
  }
}
.cb-layout1 .section--four .area .unit {
  width: 102.64550265%;
  margin-left: -1.32275132%;
  min-height: 47.65625vw;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 51.32275132%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.cb-layout1 .section--four .area .unit .part.pict:first-child {
  position: absolute;
  left: 50.34364261%;
  top: 0;
  width: 70.36082474%;
  height: 100%;
  margin-left: 1.28865979%;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.cb-layout1 .section--four .area .unit .part.pict:first-child * {
  height: 100%;
}
.cb-layout1 .section--four .area .unit .part.pict:first-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-layout1 .section--four .area .unit .part {
  width: 94.19795222%;
  margin-left: 2.55972696%;
  margin-right: 2.55972696%;
}
.cb-layout1 .section--four .area .unit .part.tiny {
  width: 44.53924915%;
}
.cb-layout2 .section--two .area,
.cb-layout2 .section--three .area,
.cb-layout2 .section--four .area {
  width: 100%;
  width: 102.64550265%;
  margin-left: -1.32275132%;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.cb-layout2 .section--two .area > .unit,
.cb-layout2 .section--three .area > .unit,
.cb-layout2 .section--four .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .section--two .area .cb-album .body,
.cb-layout2 .section--three .area .cb-album .body,
.cb-layout2 .section--four .area .cb-album .body,
.cb-layout2 .section--two .area .head,
.cb-layout2 .section--three .area .head,
.cb-layout2 .section--four .area .head,
.cb-layout2 .section--two .area .foot,
.cb-layout2 .section--three .area .foot,
.cb-layout2 .section--four .area .foot,
.cb-layout2 .section--two .area .part,
.cb-layout2 .section--three .area .part,
.cb-layout2 .section--four .area .part {
  margin-right: 1.28865979%;
  margin-left: 1.28865979%;
  width: 97.42268041%;
}
.cb-layout2 .section--two .area .tiny,
.cb-layout2 .section--three .area .tiny,
.cb-layout2 .section--four .area .tiny {
  width: 30.75601375%;
}
.cb-layout2 .section--two .area > .slim,
.cb-layout2 .section--three .area > .slim,
.cb-layout2 .section--four .area > .slim {
  width: 50%;
}
.cb-layout2 .section--two .area > .slim .head,
.cb-layout2 .section--three .area > .slim .head,
.cb-layout2 .section--four .area > .slim .head,
.cb-layout2 .section--two .area > .slim .foot,
.cb-layout2 .section--three .area > .slim .foot,
.cb-layout2 .section--four .area > .slim .foot,
.cb-layout2 .section--two .area > .slim .part,
.cb-layout2 .section--three .area > .slim .part,
.cb-layout2 .section--four .area > .slim .part {
  margin-right: 2.57731959%;
  margin-left: 2.57731959%;
  width: 94.84536082%;
}
.cb-layout2 .section--two .area > .slim.cb-album .body,
.cb-layout2 .section--three .area > .slim.cb-album .body,
.cb-layout2 .section--four .area > .slim.cb-album .body {
  margin-right: 2.57731959%;
  margin-left: 2.57731959%;
  width: 94.84536082%;
}
.cb-layout2 .section--two .area > .slim .tiny,
.cb-layout2 .section--three .area > .slim .tiny,
.cb-layout2 .section--four .area > .slim .tiny {
  width: 44.84536082%;
}
.cb-layout3 .section--two .area {
  margin-bottom: 50px;
}
.cb-layout3 .section--two .area .unit {
  width: 102.64550265%;
  margin-left: -1.32275132%;
  min-height: 30.625vw;
  padding-right: 51.32275132%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.cb-layout3 .section--two .area .unit .part.pict:last-child {
  position: absolute;
  right: 0;
  top: 0;
  width: 47.42268041%;
  height: calc(100% - 24px);
  margin-left: 1.28865979%;
  margin-right: 1.28865979%;
  padding-left: 0;
}
.cb-layout3 .section--two .area .unit .part.pict:last-child * {
  height: 100%;
}
.cb-layout3 .section--two .area .unit .part.pict:last-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-layout3 .section--two .area .unit.slim {
  padding-left: 51.32275132%;
  padding-right: 0;
}
.cb-layout3 .section--two .area .unit.slim .part.pict:last-child {
  left: 0;
  right: auto;
}
.cb-layout3 .section--two .area .unit .part {
  width: 94.84536082%;
  margin-left: 2.57731959%;
  margin-right: 2.57731959%;
  padding-left: 35px;
}
.cb-layout3 .section--two .area .unit .part.tiny {
  width: 44.84536082%;
}
.cb-layout3 .section--two .area .unit:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 12px;
  transform: translateX(-50%);
  width: 3px;
  height: calc(100% - 24px);
  background-color: var(--sitecolor);
}
@media (min-width: 1586px) {
  .cb-layout3 .section--two .area .unit {
    min-height: 432px;
  }
}
/*# sourceMappingURL=./screen-medium.css.map */